import { render, staticRenderFns } from "./FirstFrameVideo.vue?vue&type=template&id=92943764&scoped=true&"
import script from "./FirstFrameVideo.vue?vue&type=script&lang=js&"
export * from "./FirstFrameVideo.vue?vue&type=script&lang=js&"
import style0 from "./FirstFrameVideo.vue?vue&type=style&index=0&id=92943764&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92943764",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Video: require('/opt/build/repo/apps/shop/components/storyblok/Video/Video.vue').default})
