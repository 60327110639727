//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FCImage from './FCImage.vue';

export default {
  name: 'FirstFrameVideo',
  components: {
    FCImage,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
    firstFrameUrl: {
      type: String,
      required: true,
    },
    imgAlt: {
      type: String,
      required: true,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isOverlayPresent() {
      return this.overlay;
    },
  },
};
